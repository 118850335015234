import  React from 'react';
import Modal from 'react-bootstrap/Modal';
import './index.scss';

export const ApplyModal = ({ isViewApplyModal, closeViewApplyModal, job, setJobToApplyCoverLetter, handleJobApply }) => {
  const onTextAreaChange = (event) => {
    const { value } = event.target;
    setJobToApplyCoverLetter(value);
  }

  return (
    <Modal show={isViewApplyModal} onHide={closeViewApplyModal} size='lg'>
      <Modal.Body>
        <button className="btn close" onClick={closeViewApplyModal}>
          <span aria-hidden="true">×</span>
        </button>

        <div className='container apply-modal__container'>
          <div className='col-sm-12 apply-modal__page'>
            <h2 className='apply-modal__page__header'>{job?.jobTitle}</h2>
            <p className='apply-modal__page__details'>Sell yourself to this Employer?</p>
          </div>

          <textarea className='apply-modal__content' onChange={onTextAreaChange} />

          <div className='apply-modal__view_profile'>
            <button className='apply-modal__view_profile-button' onClick={handleJobApply}>Apply</button>
          </div>

        </div>
      </Modal.Body>
    </Modal>
  );
}
