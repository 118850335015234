import React from "react";
import Footer from "../../components/layout/Footer";
import { Navbar } from "../../components/v2/navbar";
import "./index.css";

// Image import
import Mission from "./images/mission-image.jpg";
import Vision from "./images/vision-image.jpg";
import CoreValues from "./images/core-values.jpg";

const Index = () => {
  return (
    <>
      <Navbar />
      <div id="about">
        <section className="banner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="vertical-center">
                <h1 className="">Who We Are</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="my-4 py-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 text-start position-relative">
                <span className="bar"></span>
                <h2 className="mb-4">Our Mission</h2>
                <p className="mb-0">
                  Showkonnect is on a mission to help entertainers earn money for their skills.
                  We do this by matching them with paying jobs near them.
                  Most entertainers find work through referrals but that only leads to a system that restricts
                  opportunities for entertainers who have a hard time getting discovered in the first place.
                  Showkonnect is a platform that makes it easy for anyone to be discovered and find a paying gig.{" "}
                </p>
              </div>

              <div className="col-lg-5 offset-lg-2 position-relativ">
                <div className="position-relative">
                  <div className="mission-abstract d-none d-lg-block"></div>
                  <img
                    src="https://res.cloudinary.com/showkonnect/image/upload/v1732770829/SiteImages/7EDC6434-B3DA-4F74-9AFB-551A8A4EF4DF_zdfe7u.jpg"
                    alt="Our Mission Image"
                    title="Our Mission"
                    className="img-fluid mt-5 mt-lg-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="my-4 py-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="offset-lg-2 col-lg-5 text-start position-relative">
                <span className="bar"></span>
                <h2 className="mb-4">Our Vision</h2>
                <p className="mb-0">
                  We are building Showkonnect to be the go-to platform for the millions of
                  entertainers in Africa to find recurring paying work.{" "}
                </p>
              </div>

              <div className="col-lg-5 order-lg-first">
                <div className="position-relative">
                  <div className="vision-abstract d-none d-lg-block"></div>
                  <img
                    src="https://res.cloudinary.com/showkonnect/image/upload/v1732770827/SiteImages/A1E24D92-D526-4378-BFD8-45D83B7BD060_l6nrzw.jpg"
                    // src={Vision}
                    alt="Our Vision"
                    title="Our Vision"
                    className="img-fluid mt-5 mt-lg-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="my-4 py-5 core-values">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 text-start position-relative">
                <span className="bar"></span>
                <h2 className="mb-4">Our Core Values</h2>
                {/*<p>*/}
                {/*  Mel causae nostrud luptatum ad, eu nostro Eos graeci salutatus*/}
                {/*  id, ad soluta atomorum reformidans*/}
                {/*</p>*/}

                <ul>
                  <li className="my-4">
                    Transparency
                  </li>

                  <li className="my-4">
                    Excellence
                  </li>
                </ul>
              </div>

              <div className="col-lg-5 offset-lg-2">
                <img
                  src={CoreValues}
                  alt="Our Core Values"
                  title="Our Core Values"
                  className="img-fluid mt-5 mt-lg-3"
                />
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default Index;
